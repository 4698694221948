import React, { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import * as Sentry from '@sentry/gatsby'
import SignUpForm from 'components/sign-up-form'
import StartOverError from 'components/start-over-error'
import { useProgramFields } from 'hooks/use-program-fields'
import {
  EnrollmentFormInputs,
  formatEnrollmentObjForSubmission,
  isEmergencyCanadianProgram,
} from 'lib/signup-form-helpers'
import { createOrUpdateSignup, getSignupCallbackURL } from 'lib/uwp'
import { ContentfulSectionForm } from 'types/contentful'
import * as styles from './section-signup-form.module.css'
import { useSignupObject } from 'hooks/use-signup-object'

type SectionSignupFormProps = {
  partnerSlug: string
  programSlug: string
  pathParam?: string | null
  index: number
  sectionForm: ContentfulSectionForm
  signUpToken?: string | null
}

const SectionCallToAction = ({
  partnerSlug,
  programSlug,
  pathParam,
  index,
  sectionForm,
}: SectionSignupFormProps) => {
  const [error, setError] = useState<string | null>()
  const [signUpToken, setSignUpToken] = useState<string | null>()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSignUpToken(urlParams.get('signUpToken'))
  }, [])

  const { signupObj } = useSignupObject({
    signUpToken,
  })
  const { programFields, refetch } = useProgramFields({
    programKey: programSlug,
    onError: () => {
      setError(
        'There was an error displaying the form. Please try again later.'
      )
    },
  })

  const onSubmit: SubmitHandler<EnrollmentFormInputs> = (formFields) => {
    const enrollmentForSubmission = formatEnrollmentObjForSubmission({
      enrollmentFormInputs: formFields,
      program: programSlug,
    })
    createOrUpdateSignup(
      partnerSlug,
      programSlug,
      enrollmentForSubmission,
      signUpToken
    )
      .then((resp) => {
        getSignupCallbackURL({
          partnerKey: partnerSlug,
          programKey: programSlug,
          pathParam,
          signUpToken: resp.signUpId,
        })
          .then((callbackUrl) => {
            window.location.href = callbackUrl
          })
          .catch((e) => {
            console.error(e)
            setError(
              'There was an error redirecting you to your partner connection page.'
            )
            Sentry.captureException(e)
          })
      })
      .catch((e) => {
        console.error(e)
        setError('There was an error submitting your information.')
        Sentry.captureException(e)
      })
  }

  return (
    <div className={styles.sectionSignupForm} data-index={index}>
      {error && (
        <StartOverError
          error={error}
          supportEmail={sectionForm.supportEmail}
          programSlug={programSlug}
          onStartOver={() => {
            setError(null)
            refetch()
          }}
        />
      )}
      {!error && (
        <SignUpForm
          isAmericanProgram={!isEmergencyCanadianProgram(programSlug)}
          sectionForm={sectionForm}
          signupObj={signupObj ?? null}
          onSubmit={onSubmit}
          programFields={programFields}
        />
      )}
    </div>
  )
}

export default SectionCallToAction
